import React from 'react';
import classNames from '../helpers/classNames';
import { PropComponent } from '../helpers/dataHelpers';
import style from './chip.module.scss';

interface ChipProps {
  className?: string,
  clicked?: () => void
}

const Chip: PropComponent<ChipProps> = ({className, children, clicked}) => {
  return(
    <span className={classNames(className, style.chip)} onClick={() => {clicked ? clicked(): null}}>
          {children}
    </span>
  )
};

export default Chip;
