import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React, { useState } from 'react';
import BetterHeader from '../components/header/betterHeader';
import { RichTextOptions } from '../components/richTextOptions';
import { findImageByContentId } from '../helpers/contentfulHelpers';
import style from './article.module.scss';
import Img from 'gatsby-image';
import Contact from '../components/home/home.contact';
import Footer from '../components/footer';
import ShareModal from '../components/shareModal';
import Chip from '../components/chip';
import SEO from '../components/seo';

const Article = ({ pageContext, path }) => {
  const { data, assets } = pageContext;
  const photo = data?.articleImage?.contentful_id
    ? findImageByContentId(assets, data?.articleImage?.contentful_id)
    : undefined;
  const created = new Intl.DateTimeFormat('en', {
    month: 'long',
    year: 'numeric',
    day: '2-digit',
  }).format(new Date(data.createdAt));

  return (
    <div>
      <SEO></SEO>
      <BetterHeader plainBackground={true}></BetterHeader>
      <div className={style.pageContainer}>
        <article className={style.pageContent}>
          <div className={style.headerContent}>
            <div className={style.headerInfo}>
              <div className={style.category}>{data?.category?.category}</div>
              <h1 className={style.title}>{data?.title}</h1>
              <h5 className={style.author}>{data?.author}</h5>
              <h5 className={style.created}>{created}</h5>

              {data?.tags?.map((t, i) => {
                return (
                  <Chip className={style.chips} key={t.name}>
                    <span>{t.name}</span>
                  </Chip>
                );
              })}
              <div>
                <ShareModal url={`article/${data.slug}`}></ShareModal>
              </div>
            </div>
            {photo && (
              <div className={style.headerImage}>
                <Img
                  className={style.photo}
                  fluid={photo.fluid}
                  fadeIn={false}
                  loading="eager"
                />
              </div>
            )}
          </div>

          <section className={style.content}>
            {data?.content?.json &&
              documentToReactComponents(
                data.content.json,
                RichTextOptions(assets)
              )}
          </section>
        </article>
      </div>
      <Contact
        contactTitle={''}
        contactLink="/contact"
        contactButton={'Contact Us'}
      ></Contact>
      <Footer></Footer>
    </div>
  );
};

export default Article;
